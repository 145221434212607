const startGameMutation = (skin, gameId, mode, redirectUris) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "se") {
        skin = "bo";
    }

    return `mutation{
    startGame(input: {brandId:"${skin}", slug:"${gameId}", mode:${mode}, redirectUris: ${redirectUris}}){
      properties
    }
  }`;
};

const loginMutation = (skin, username, password) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "se") {
        skin = "bo";
    }

    return `mutation {
    login(input: {brandId: "${skin}",
                 password: "${password}",
                 username: "${username}"}){
                  accessToken
                  refreshToken
                  expiresAt
                  userId
   }
 }`;
};

const loginWithTokenMutation = (skin, token) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "se") {
        skin = "bo";
    }

    return `mutation {
    loginWithToken(input: {brandId: "${skin}",
                 token: "${token}"}){
                  accessToken
                  refreshToken
                  expiresAt
                  userId
   }
 }`;
};

const verifyWithAuthMutation = (skin, code) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "se") {
        skin = "bo";
    }

    return `mutation {
  verifyWithAuth(input:{brandId: "${skin}", code: "${code}"}){
    accessToken
    refreshToken
    expiresAt
    userId
  }
}`;
};

const refreshTokenMutation = (refreshToken) => `mutation{
    refreshToken(input:{ refreshToken: "${refreshToken}" }){
      accessToken
      refreshToken
      expiresAt
    }
}`;

const createUserMutation = (queryInput) => `mutation{
  createUser(input: ${queryInput}){
    user{
      userId
    }
  }
}`;

const updateUserMutation = (queryInput) => `mutation{
  updateUser(input: ${queryInput}){
    user{
      userId
    }
  }
}`;

const changePasswordMutation = (userId, password) => `mutation{
  changePassword(input: {userId: "${userId}", password: "${password}"} ){
  	user{
      userId
    }
  }
}`;

const createLockMutation = (queryInput) => `mutation{
  createLock(input: ${queryInput}){
    lock{
      lockId
    }
  }
}`;

const setUserLimitsMutation = (queryInput) => `mutation{
  setUserLimits(input: ${queryInput}){
    userLimits{
      createdAt
      type
      value
      status
      progress
      period
      nextResetTime
      userLimitId
    }
  }
}`;

const createUploadUriMutation = (userId) => `mutation{
  createUploadUri(input: {userId: "${userId}"}){
    objectUrl
    objectPath
    uploadUrl
  }
}`;

const provideDocumentMutation = (userId, type, path) => `mutation{
  provideDocument(input: {userId: "${userId}", type: ${type}, path: "${path}"} ){
    document{
      documentId
      url
      status
      createdAt
    }
  }
}`;

const activateUserBonusMutation = (userId, userBonusId) => `mutation{
  activateUserBonus(input:{userId:"${userId}", userBonusId: "${userBonusId}", activationType:MANUAL}){
   userBonus{
     bonusId
   }
 }
}`;

const cancelUserBonusMutation = (userId, userBonusId) => `mutation{
  cancelUserBonus(input:{userId:"${userId}", userBonusId: "${userBonusId}"}){
   userBonus{
     bonusId
   }
 }
}`;

const recoverPasswordMutation = (skin, email, redirectUri) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "se") {
        skin = "bo";
    }

    return `mutation{
      recoverPassword(input: {brandId: "${skin}", email:"${email}", redirectUri: "${redirectUri}"}){
        email
      }
    }`;
};

const resetPasswordMutation = (skin, password, hash) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "se") {
        skin = "bo";
    }

    return `mutation{
      resetPassword(input: {brandId: "${skin}", password: "${password}", hash: "${hash}"} ){
        accessToken
        expiresAt
        refreshToken
        userId
      }
    }`;
};

const createPaymentMutation = (queryInput) => `mutation{
  createPayment(input:${queryInput}){
    payment{
      paymentId
      declineReason
      errorMessage
      statusMessage
      status
    }
    redirect{
      html
      iframeable
      uri
    }
  }
}`;

const cancelPaymentMutation = (paymentId) => `mutation{
  cancelPayment(input:{paymentId: "${paymentId}"}){
    payment{
      paymentId
    }
  }
}`;

const purchaseShopItemsMutation = (shopItemIds) => `mutation{
  purchaseShopItems(input:{shopItemIds: ${shopItemIds}}){
    shopTransaction{
      status
    }
  }
}`;

const cancelUserLimitMutation = (userId, userLimitId) => `mutation{
  cancelUserLimit(input:{userId: "${userId}", userLimitId: "${userLimitId}"}){
    userLimit{
      userLimitId
    }
  }
}`;

const openUserBoxMutation = (userBoxId) => `mutation{
  openUserBox(input:{userBoxId: "${userBoxId}"}){
    userBox{
      reward{
        rewardId
        action{
          __typename
          ... on GiveAndActivateBonusAction {
            bonusId
          }
        }
      }
    }
  }
}`;

const authorizeProviderMutation = (queryInput) => `mutation{
  authorizeProvider(input:${queryInput}){
    code
    redirectUri
  }
}`;

export {
    startGameMutation,
    loginMutation,
    loginWithTokenMutation,
    verifyWithAuthMutation,
    refreshTokenMutation,
    createUserMutation,
    updateUserMutation,
    changePasswordMutation,
    createLockMutation,
    setUserLimitsMutation,
    createUploadUriMutation,
    provideDocumentMutation,
    activateUserBonusMutation,
    cancelUserBonusMutation,
    recoverPasswordMutation,
    resetPasswordMutation,
    createPaymentMutation,
    cancelPaymentMutation,
    purchaseShopItemsMutation,
    cancelUserLimitMutation,
    openUserBoxMutation,
    authorizeProviderMutation,
};
